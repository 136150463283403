import { PreCheckMarkingCountryList } from '@experiences/constants';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CheckBoxLabel } from '../../components/SignupPageComponent/subcomponents/util/SignupUtilComponents';
import { useLocalization } from '../../container/Localization';
import { useParams } from '../../container/Router';
import useTranslatedCountries from '../../locales/useTranslatedCountries';
import { logSignupSubmit } from '../../telemetry';
import { privacyLinks } from '../../util/constants/LegalLinks';
import states from '../../util/constants/States';
import useAuthInstance from '../../util/hooks/AuthInstance';
import { useIsCustomerPortal } from '../../util/hooks/IsCustomerPortal';
import { useIsMarketplace } from '../../util/hooks/isMarketplace';
import { useSignupType } from '../../util/hooks/SignupType';
import type {
    ISignup,
    ISignupDto,
} from '../../util/interfaces/signup';
import { addUserMarketplace } from '../../util/MarketoLookUp';
import { UiEmail } from '../UiEmail';
import UiPassword from '../UiPassword/UiPassword';
import { UiProgressButton } from '../UiProgressButton/UiProgressButton';
import UiSelect from '../UiSelect/UiSelect';

const useStyles = makeStyles(() =>
    createStyles({
        form: {
            margin: '16px 0 8px 0',
            width: '100%',
        },
        divider: { margin: '12px 0px' },
        formControl: { display: 'flex' },
        checkbox: {
            height: '20px',
            marginTop: '-9px',
        },
        checkboxLabel: {
            fontSize: '14px',
            width: '340px',
        },
        privacyFormControl: { alignItems: 'safe center' },
        marketingFormControl: {
            alignItems: 'safe center',
            marginTop: '8px',
        },
        checkBoxForm: { marginTop: '12px' },
    }),
);

export const LegacySignupFormComponent = () => {
    const classes = useStyles();

    const getAuthInstance = useAuthInstance();

    const params = useParams();
    const language = useLocalization(true);
    const { formatMessage: translate } = useIntl();
    const { signupType } = useSignupType();
    const countries = useTranslatedCountries();
    const isMarketplace = useIsMarketplace();
    const isCustomerPortal = useIsCustomerPortal();

    const [ loading, setLoading ] = useState(false);
    const [ marketingPreCheck, setMarketingPreCheck ] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: {
            isValid, errors,
        },
    } = useForm<ISignup>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
            organizationName: '', // TODO: is this needed?
            firstName: '',
            lastName: '',
            jobTitle: '',
            country: '',
            state: '',
            email: params.login_hint ?? '',
            password: '',
            termsAndConditionsAccepted: false,
            marketingConditionsAccepted: false,
        },
    });

    const country = watch('country');

    useEffect(() => {
        if (PreCheckMarkingCountryList.includes(country) && !marketingPreCheck) {
            setValue('marketingConditionsAccepted', true);
            setMarketingPreCheck(true);
        } else if (!PreCheckMarkingCountryList.includes(country)) {
            setMarketingPreCheck(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ country, setValue ]);

    const onSubmit = useCallback(
        async (data: ISignup) => {
            setLoading(true);
            const {
                termsAndConditionsAccepted, ...rest
            } = data;

            const payload: ISignupDto = {
                ...rest,
                language,
                userSignupSubscriptionPlan: params.subscription_plan,
                platformName: signupType,
                companyCode: params.company_code,
                isUserInvite: false,
            };

            logSignupSubmit(params.subscription_plan);

            if (isMarketplace) {
                addUserMarketplace(data);
            }

            getAuthInstance().basicSignupDB(payload, isCustomerPortal, (err) => {
                let errorCode = 'defaultError';
                switch (err?.code) {
                    case 'user_exists':
                        errorCode = 'userExists';
                        break;
                    case 'server_error':
                    case 'extensibility_error':
                        errorCode = 'useSso';
                        break;
                    default: break;
                }
                setError('email', { type: errorCode });
                setLoading(false);
            });
        },
        [
            language,
            params.subscription_plan,
            params.company_code,
            signupType,
            isMarketplace,
            getAuthInstance,
            isCustomerPortal,
            setError,
        ],
    );

    return (
        <form
            id="basic-auth-signup-form"
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={classes.formControl}>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                required
                                variant="outlined"
                                label={translate({ id: 'FIRST_NAME' })}
                                InputLabelProps={{ id: 'firstNameLabel' }}
                                fullWidth
                                inputProps={{
                                    'data-cy': 'signup-first-name-input',
                                    'aria-labelledby': 'firstNameLabel',
                                }}
                            />
                        )}
                        control={control}
                        name="firstName"
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 256,
                        }}
                    />
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                required
                                variant="outlined"
                                label={translate({ id: 'LAST_NAME' })}
                                InputLabelProps={{ id: 'lastNameLabel' }}
                                style={{ marginLeft: '8px' }}
                                fullWidth
                                inputProps={{
                                    'data-cy': 'signup-last-name-input',
                                    'aria-labelledby': 'lastNameLabel',
                                }}
                            />
                        )}
                        control={control}
                        name="lastName"
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 256,
                        }}
                    />
                </div>
                {isCustomerPortal && (
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                required
                                variant="outlined"
                                label={translate({ id: 'JOB_TITLE' })}
                                InputLabelProps={{ id: 'jobTitleLabel' }}
                                fullWidth
                                className={classes.formControl}
                                inputProps={{
                                    'data-cy': 'signup-job-title-freetext',
                                    'aria-labelledby': 'jobTitleLabel',
                                }}
                            />
                        )}
                        control={control}
                        name="jobTitle"
                        rules={{
                            required: true,
                            minLength: 1,
                            maxLength: 256,
                        }}
                    />
                )}
                <UiSelect
                    control={control}
                    name="country"
                    inputLabel={translate({ id: 'SELECT_COUNTRY' })}
                    options={countries}
                    required
                    className={classes.formControl}
                    dataCy="signup-country-select"
                    useValues
                />
                {isCustomerPortal && signupType && states[country] && (
                    <UiSelect
                        control={control}
                        name="state"
                        inputLabel={translate({ id: 'SELECT_STATE' })}
                        options={states[country]}
                        required
                        className={classes.formControl}
                        dataCy="signup-state-select"
                    />
                )}
                <UiEmail
                    control={control}
                    name="email"
                    errors={errors}
                    label={!isCustomerPortal ? translate({ id: 'EMAIL' }) : translate({ id: 'EMAIL_BUSINESS_V2' })}
                    InputProps={{
                        readOnly: !!params.user_invite,
                        className: params.user_invite ? 'Mui-disabled' : '',
                    }}
                    className={classes.formControl}
                    fullWidth
                    InputLabelProps={{ id: 'emailLabel' }}
                    inputProps={{
                        'data-cy': 'signup-email-input',
                        'aria-labelledby': 'emailLabel',
                    }}
                    data-cy="signup-email-textfield"
                />
                <UiPassword
                    control={control}
                    name="password"
                    className={classes.formControl}
                    useDefaultValidation
                    dataCy="signup-password-input"
                />
            </div>
            <div className={classes.checkBoxForm}>
                <Controller
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox
                                className={classes.checkbox}
                                color="primary"
                                size="small"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                            />}
                            label={
                                <CheckBoxLabel
                                    label="TERMS_AND_CONDITIONS"
                                    href={[
                                        'https://www.uipath.com/legal/trust-and-security/legal-support',
                                        'https://www.uipath.com/legal/trust-and-security/legal-support#content-3',
                                    ]}
                                    hrefText={[ 'LICENSE_TERMS', 'ACCEPTABLE_USE' ]}
                                />
                            }
                            classes={{ label: classes.checkboxLabel }}
                            className={classes.privacyFormControl}
                            data-cy="signup-terms-and-conditions-check"
                        />
                    )}
                    rules={{ required: true }}
                    name="termsAndConditionsAccepted"
                    control={control}
                    data-cy="signup-terms-and-conditions-check"
                />
                <Controller
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox
                                className={classes.checkbox}
                                color="primary"
                                size="small"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                            />}
                            label={
                                <CheckBoxLabel
                                    label="MARKETING_CONDITION"
                                    href={[ privacyLinks.get(language) ?? 'https://www.uipath.com/legal/privacy-policy' ]}
                                    hrefText={[ 'PRIVACY_POLICY' ]}
                                />
                            }
                            classes={{ label: classes.checkboxLabel }}
                            className={classes.marketingFormControl}
                            data-cy="signup-privacy-policy-check"
                        />
                    )}
                    name="marketingConditionsAccepted"
                    control={control}
                    data-cy="signup-privacy-policy-check"
                />
            </div>

            <div role="presentation">
                <UiProgressButton
                    loading={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '12px' }}
                    disabled={!isValid}
                    fullWidth
                    data-cy="signup-submit-button"
                >
                    {translate({ id: 'CONTINUE_WITH_EMAIL' })}
                </UiProgressButton>
            </div>
        </form>
    );
};
