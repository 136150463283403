import { useMemo } from 'react';

import { useParams } from '../../container/Router';

export const useIsLegacyForm = () => {
    const { redirect_uri } = useParams();

    return useMemo(() => redirect_uri?.includes('forum')
    || redirect_uri?.includes('marketplace')
    || redirect_uri?.includes('customerportal'),
    [ redirect_uri ]);
};
