const states: { [key: string]: string[] } = {
    Australia: [
        'New South Wales',
        'Queensland',
        'South Australia',
        'Tasmania',
        'Victoria',
        'Western Australia',
        'Australian Capital Territory',
        'Northern Territory',
    ],
    China: [
        'Anhui',
        'Beijing',
        'Fujian',
        'Gansu',
        'Guangdong',
        'Guizhou',
        'Hainan',
        'Hebei',
        'Heilongjiang',
        'Henan',
        'Hubei',
        'Hunan',
        'Jiangsu',
        'Jiangxi',
        'Jilin',
        'Liaoning',
        'Qinghai',
        'Shaanxi',
        'Shandong',
        'Shanghai',
        'Shanxi',
        'Sichuan',
        'Yunnan',
        'Zhejiang',
    ],
    'United States': [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
        'District of Columbia',
        'American Samoa',
        'Guam',
        'Northern Mariana Islands',
        'Puerto Rico',
        'United States Minor Outlying Islands',
        'Virgin Islands, U.S.',
    ],
};

export default states;
